import * as React from "react";
import { useIntl } from "react-intl";
import Layout from "../components/Layout";


const NotFoundPage = () => {
    const intl = useIntl();
    
    return (
        <Layout>
            <main className="container">
                <div className="row">
                    <section className="error">
                        <h1>{intl.formatMessage({ id: "error.title" })}</h1>
                        <div dangerouslySetInnerHTML={{
                            __html: intl.formatMessage({ id: "error.description" })
                        }} />
                    </section>
                </div>
            </main>    
        </Layout>
    );
}

export default NotFoundPage